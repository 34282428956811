import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import 'antd/dist/reset.css';
import './fonts.css';
import 'shared/config/rest.config';
import 'shared/config/dates.config';
import 'shared/config/pendo.config';
import { Provider } from 'react-redux'
import {store} from 'state/store'
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { config } from 'shared/config/cts.config';

document.execCommand('defaultParagraphSeparator', false, 'p');

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LDProvider
        clientSideID={`${config.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID}`}
      >
      <App />
      </LDProvider>
    </Provider>
  </React.StrictMode>
);
