import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UserState {
  value: any
}

const initialState: UserState = {
  value: null,
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setStoreUser: (state, action: PayloadAction<any>) => {
      state.value = action.payload
    }
  }
})

export const { setStoreUser } = userSlice.actions;

export default userSlice.reducer;