/* eslint-disable no-console */
import { DateTime } from 'luxon';
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import useAuthToken from 'shared/datasources/auth/use-auth-token.hook';
import useAuthUser from 'shared/datasources/auth/use-auth-user.hook';
import { useLogin } from 'shared/datasources/auth/use-login.hook';
import { UserStates } from 'shared/hooks/admin/states.enum';
import { useUserStateNoContext } from 'shared/hooks/admin/use-post-update-user-state.hook';
import { useSendDataScienceEventMessage } from 'shared/hooks/data-science-api/use-send-data-science-event-message.hook';
import {
  LogLevelType,
  usePostDynatraceLogsNoContext,
} from 'shared/hooks/dynatrace/use-dynatrace';
import { useAppUser } from 'shared/hooks/use-app-user';
import {
  useStateStorage,
  LocalStorageKeys,
  TokensInfo,
  TokenUser,
} from 'shared/hooks/use-state-storage.hook';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/store';
import { setStoreUser } from 'state/user/userSlice';
import { useQuickWords } from 'shared/hooks/use-quickwords';

const AGENT_APP_ACCESS = 'Agent App Access';

interface OAuthProviderProps {
  children: React.ReactNode;
}

interface OAuthContextValue {
  tokenUser: TokenUser | null;
  accessToken: string;
  logout: () => void;
  isLoading: boolean;
}

const OAuthContext = createContext<OAuthContextValue>({
  tokenUser: null,
  accessToken: '',
  logout: () => { },
  isLoading: true,
});

const refreshTime = 30 * 60 * 1000;
const refreshTimeLimit = 30 * 60 * 1000;// 9 * 60 * 60 * 1000;

const OAuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { loadQuickwords } = useQuickWords();

  const handleCallbackCalled = useRef(false);
  const refreshLoginTimeoutId = useRef<number | NodeJS.Timeout>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch()
  const [tokens, setTokens] = useStateStorage<TokensInfo>(
    LocalStorageKeys.tokens,
    {
      accessToken: '',
      refreshToken: '',
    }
  );
  const [tokenUser, setUser] = useStateStorage<TokenUser | null>(
    LocalStorageKeys.user,
    null
  );
  const [initialAuthTime, setInitialAuthTime] = useStateStorage<number | null>(
    LocalStorageKeys.authTime,
    null
  );
  const [loginState, setLoginState] = useStateStorage<string | null>(
    LocalStorageKeys.state,
    null
  );
  const user = useSelector((state: RootState) => state.user.value);

  const { getUser } = useAuthUser<TokenUser>();
  const { getAppUser } = useAppUser();
  const [getToken, refreshToken] = useAuthToken();
  const { login } = useLogin();
  const { sendSessionLoginEventMessage, sendSessionLogoutEventMessage } =
    useSendDataScienceEventMessage();
  const { log } = usePostDynatraceLogsNoContext();
  const { updateUserState } = useUserStateNoContext();

  const generateState = () => {
    const state = Math.random().toString(36).substring(7);
    localStorage.setItem('state', state);
    setLoginState(state);
    return state;
  };

  const logout = async () => {
    if (tokens.accessToken && tokenUser) {
      await sendSessionLogoutEventMessage(
        tokens.accessToken,
        tokenUser.jti,
        tokenUser.employeeID,
        `${DateTime.now().toISO()}`,
        `${DateTime.now().toISO()}`
      );
      await updateUserState(tokens.accessToken, {
        stateName: UserStates.OFFLINE,
        sessionId: tokenUser?.jti as string,
      });
    }
    setTokens({
      accessToken: '',
      refreshToken: '',
    });
    setUser(null);
    setInitialAuthTime(null);
    localStorage.removeItem('last-token-time')
    login(generateState());
  };

  async function validateUserAuthorization(
    accessToken: string,
    user: TokenUser
  ) {
    const appUser = await getAppUser(accessToken, user.objectGUID);
    let userHasAccess = false;
    let agentAppAccessPermission;
    let logMessage = '';
    if (!appUser) {
      logMessage = `User not found.`;
    } else if (!appUser.role) {
      logMessage = `User does not have a role`;
    } else if (!appUser.role?.permissions?.length) {
      logMessage = `User role ${appUser.role.name} has no permissions`;
    } else {
      agentAppAccessPermission = appUser.role.permissions.find(
        (permission: any) => permission.code === AGENT_APP_ACCESS
      );
      userHasAccess = !!agentAppAccessPermission;
      logMessage = `User role does not have ${AGENT_APP_ACCESS}`;
    }
    if (!userHasAccess) {
      await log(accessToken, {
        logLevelType: LogLevelType.WARN,
        methodName: 'validateUserAuthorization',
        agentNumber: user?.employeeID,
        parameters: {
          accessToken: !accessToken ? accessToken : 'has value',
          user: {
            objectGUID: user?.objectGUID,
            username: user?.sub,
            employeeID: user?.employeeID,
            role: appUser?.role?.name,
          },
        },
        message: logMessage,
      });
      return null;
    }
    return appUser;
  }

  const handleCallback = async (code: string, state: string) => {
    if (state !== loginState && !window.electronAPI) {
      console.error('Invalid state');
      logout();
      return;
    }
    try {
      const response = await getToken(code);
      const { access_token: accessToken, refresh_token: refreshToken } =
        response.data;
      setTokens({
        accessToken,
        refreshToken,
      });
      const userRespone = await getUser(accessToken);
      const user = userRespone?.data;
      localStorage.setItem('token-expiration', user?.exp.toString());
      const appUser = await validateUserAuthorization(accessToken, user);
      if (!appUser) {
        setIsLoading(false);
        return;
      }
      user.userId = appUser.userId;
      user.agentNumber = appUser.agentNumber;
      user.agentEmail = appUser.agentEmail;
      user.isActive = appUser.isActive;
      user.no911Calls = appUser.no911Calls;
      user.creationDt = appUser.creationDt;
      user.modifiedDt = appUser.modifiedDt;
      user.modifiedBy = appUser.modifiedBy;
      user.language = appUser.language;
      user.role = appUser.role;
      user.callCenters = appUser.callCenters;
      const homeCallCenter = tokenUser?.callCenters.find(
        (callCenter) => callCenter.isHomeCallCenter
      );
      if (homeCallCenter) {
        user.callCenterId = homeCallCenter.name;
      }
      await loadQuickwords(accessToken);
      await updateUserState(accessToken, {
        stateName: UserStates.IN_LOBBY,
        sessionId: user.jti,
      });
      await sendSessionLoginEventMessage(
        accessToken,
        user.jti,
        `${DateTime.now().toISO()}`,
        user.language,
        user.employeeID,
        '', // callCenterId
        user.callCenterId,
        '', // callCenterId
        user.callCenterId
      );
      await log(accessToken, {
        logLevelType: LogLevelType.INFO,
        agentNumber: user?.employeeID,
        message: `User ${user.agentEmail} logged in successfully`,
      });
      setInitialAuthTime(new Date().getTime());
      localStorage.setItem('last-token-time', Date.now().toString());
      dispatch(setStoreUser(user));
      setUser(user);
      setIsLoading(false);
      timedoutRefreshAccessToken();
    } catch (error) {
      console.error(error);
      if (!tokens.accessToken) {
        // getToken must have failed,
        // possibly due to an obsolete 'code'
        logout();
      }
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (tokens.accessToken) {
      setIsLoading(false);
      return;
    }
    if (!urlParams.get('code')) {
      login(generateState());
    } else if (!handleCallbackCalled.current) {
      handleCallback(urlParams.get('code')!, urlParams.get('state')!);
      handleCallbackCalled.current = true;
    }
  }, []);

  const refreshAccessToken = async () => {
    let storedTokens = localStorage.getItem('tokenInfo') || '';
    let storedTokenObject = JSON.parse(storedTokens);
    const refreshTokenValue = storedTokenObject?.refreshToken
    if (!refreshTokenValue) {
      return;
    }
    try {
      const response = await refreshToken(refreshTokenValue);
      const { access_token: accessToken } = response.data;
      setTokens({
        accessToken,
        refreshToken: refreshTokenValue,
      });
      localStorage.setItem('last-token-time', Date.now().toString());
      intervalRefreshAccessToken();
    } catch (error) {
      console.error(error);
    }
  };
  const intervalRefreshAccessToken = () => {
    refreshLoginTimeoutId.current = setInterval(refreshAccessToken, refreshTimeLimit);
  }
  const timedoutRefreshAccessToken = (delay = refreshTimeLimit) => {
    refreshLoginTimeoutId.current = setTimeout(refreshAccessToken, refreshTimeLimit);
  }

  useEffect(() => {
    if (!tokens.refreshToken) {
      // If we don't have a refreshToken then we can't do anything here
      return () => { };
    }
    const now = Date.now();
    let lastTokenTime: string | number = localStorage.getItem('last-token-time') || 0;
    lastTokenTime = parseInt(lastTokenTime.toString(), 10)
    if (lastTokenTime && !refreshLoginTimeoutId.current) {
      const maxWaitRefreshTime = lastTokenTime + refreshTimeLimit;
      if (now >= maxWaitRefreshTime) {
        refreshAccessToken();
      } else {
        let wait = maxWaitRefreshTime - now;
        timedoutRefreshAccessToken(wait);
      }
    }
  }, []);

  useEffect(() => {
    if (window?.electronAPI?.on) {
      window?.electronAPI.on('close-app', async () => {
        if (tokens.accessToken && tokenUser) {
          await updateUserState(tokens.accessToken, {
            stateName: UserStates.OFFLINE,
            sessionId: tokenUser?.jti as string,
          });
        }
        window?.electronAPI?.closeApp(false);
      });
      return () => { };
    }
  }, []);

  useEffect(() => {
    if (tokenUser && !user) {
      dispatch(setStoreUser(tokenUser))
    }
  }, []);

  (window as any).refreshSession = () => refreshAccessToken();
  (window as any).logout = () => logout();

  return (
    <OAuthContext.Provider
      value={{
        tokenUser,
        logout,
        accessToken: tokens.accessToken,
        isLoading,
      }}
    >
      {children}
    </OAuthContext.Provider>
  );
};

const useOAuth = () => {
  const auth = useContext(OAuthContext);
  if (!auth) {
    throw new Error('useOAuth must be used within an OAuthProvider');
  }
  return auth;
};

export { OAuthProvider, useOAuth };
