import { createSlice, PayloadAction } from '@reduxjs/toolkit'


export type QuickWord = {
  id: number;
  abbreviation: string;
  description: string;
};

export type QuickWordObject = {
  [key: string]: string;
};

type QuickWordData = {
  functionKeys: QuickWordObject;
  quickwords: QuickWordObject;
};

interface QuickWordsState {
  value: any
}

const initialState: QuickWordData = {
  functionKeys: {},
  quickwords: {},
}

const userSlice = createSlice({
  name: 'quickwords',
  initialState,
  reducers: {
    setFunctionKeys: (state, action: PayloadAction<QuickWordObject>) => {
      state.functionKeys = action.payload
    },
    setQuickwords: (state, action: PayloadAction<QuickWordObject>) => {
      state.quickwords = action.payload
    },
  }
})


export const { setFunctionKeys, setQuickwords } = userSlice.actions;

export default userSlice.reducer;