import {useContext} from 'react';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import PreflightChekScreen from 'modules/ipcts-call-session/pfc-screen/preflight-check.component';

const AgentLobby = () => {
  const {
    setShowQueue,
    setIsSessionScreen,
    setShowLobby,
  } = useContext(LandingPageContext);

  const {
    cardCaptionCallRef,
    handleStopListening,
    handleStartListening,
  } = useContext(IPCTSSessionContext);

  return (
    <PreflightChekScreen
      setShowQueue={setShowQueue}
      setShowLobby={setShowLobby}
      setIsSessionScreen={setIsSessionScreen}
      text={[]}
      handleStartListening={handleStartListening}
      handleStopListening={handleStopListening}
      cardCaptionCallRef={cardCaptionCallRef!}
    />
  )
}
export default AgentLobby;