import { Outlet } from 'react-router-dom';
import * as Styled from './AppLayout.styled';
import DesktopHeader from 'components/AppHeader';
import { useAllowScreenShare } from 'shared/hooks/use-allow-screen-share';

const AppLayout = () => {
  useAllowScreenShare()

  return (
    <Styled.MainLayout
      style={{
        minHeight: '100%',
        display: 'inline-block',
        width: '100%',
      }}
      className="test"
    >
      <DesktopHeader />
      <Styled.CustomLayout>
        <Styled.CustomLayoutContent>
          <Styled.CustomContent>
            <Outlet />
          </Styled.CustomContent>
        </Styled.CustomLayoutContent>
      </Styled.CustomLayout>
    </Styled.MainLayout>
  );  
}

export default AppLayout;