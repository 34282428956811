import {useContext} from 'react';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import { QueueScreen } from 'shared/components/ui/queue-screen/queue-screen.component';
import { useNavigate } from 'react-router-dom';

const AgentQueue = () => {
  const {
    setShowQueue,
    setIsSessionScreen,
    handleQueueCancel,
  } = useContext(LandingPageContext);

  const {
    sendNotAcceptingCalls,
  } = useContext(IPCTSSessionContext);

  const navigate = useNavigate();

  const handleCancelQueue = () => {
    sendNotAcceptingCalls('break', 'Out to lunch')
    navigate('/agent-lobby');
    setShowQueue(false);
    setIsSessionScreen(false);
    handleQueueCancel();    
  }

  return (
    <QueueScreen onCancelQueue={handleCancelQueue} />
  )
}

export default AgentQueue;