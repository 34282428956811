import { AuthenticationProvider } from 'modules/authentication/authentication.context';
import AppLayout from 'layouts/AppLayout/AppLayout';
import AgentLobby from 'components/AgentLobby'
import AgentQueue from 'components/AgentQueue'
import AgentSession from 'components/AgentSession'
import FullScreenLoader from 'shared/components/ui/fullScreenLoader/full-screen-loader';
import AgentProviders from 'components/AgentProviders';
import { Download } from 'modules/download/download.component';

export const routes = [
  {
    path: '/',
    element: <AuthenticationProvider><FullScreenLoader /></AuthenticationProvider>,
  },
  {
    path: '/download',
    element: <AuthenticationProvider><AppLayout /></AuthenticationProvider>,
    children: [
      {
        path: '/download',
        element: <Download />,
      },
    ]
  },
  {
    element: <AuthenticationProvider><AgentProviders><AppLayout /></AgentProviders></AuthenticationProvider>,
    children: [
      {
        path: '/agent-lobby',
        element: <AgentLobby />,
      },
      {
        path: '/agent-queue',
        element: <AgentQueue />,
      },
      {
        path: '/agent-session',
        element: <AgentSession />,
      },
    ],
  },
]

