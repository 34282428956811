import Headphones from 'assets/icons/headphones.svg';
import * as Styled from './bottom-controls.component.styled';
import { RootState } from 'state/store';
import { useSelector, useDispatch } from 'react-redux';
import { mute, unmute } from 'state/audio/audioSlice';
interface BottonControlsInterface {}

const BottomControls = ({}: BottonControlsInterface) => {
  const audio = useSelector((state: RootState) => state.audio);
  const dispatch = useDispatch();

  const toggleAudioMute = () => {
    if(audio.muted){
      dispatch(unmute())
    } else {
      dispatch(mute())      
    }
  }

  return (
    <Styled.ControlsWrapper>
      <Styled.VolumeWrapper>
        <Styled.AudioMuteButton onClick={toggleAudioMute} >
          {audio.muted ? <Styled.HeadphoneIconOff src={Headphones} /> : <Styled.HeadphoneIcon src={Headphones} />}
        </Styled.AudioMuteButton>
      </Styled.VolumeWrapper>
    </Styled.ControlsWrapper>
  );
};

export default BottomControls;
