import { useContext, useState } from 'react';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import { IpctsSession } from 'modules/ipcts-call-session/ipcts-session.component';
import { Navigate } from 'react-router-dom';
import { RootState } from 'state/store';
import { useSelector } from 'react-redux';

const AgentSession = () => {
  const [isHandoff, setIsHandoff] = useState(false);
  const captions = useSelector((state: RootState) => state.captions.value);

  const {
    isCallActive,
    setIsCallActive,
    dispositionSelected,
    setDispositionSelected,
    setSilentDispositionSelected,
  } = useContext(LandingPageContext);

  const {
    isActiveSession,
    isTokenReady,
    cardCaptionCallRef,
    handleStartListening,
    reset,
    addCustomCaption,
  } = useContext(IPCTSSessionContext);

  const dispositionCallEnd = () => {
    reset();
    setIsCallActive(false);
  };

  const handoffCallEnd = () => {
    dispositionCallEnd();
    setIsHandoff(true);
  };
  if (!isCallActive) {
    return <Navigate to="/agent-queue" />;
  }

  return (
    <IpctsSession
      text={captions}
      isActiveSession={isActiveSession}
      isTokenReady={isTokenReady}
      cardCaptionCallRef={cardCaptionCallRef}
      handleStartListening={handleStartListening}
      setIsCallActive={setIsCallActive}
      dispositionCallEnd={dispositionCallEnd}
      handoffCallEnd={handoffCallEnd}
      addCustomCaption={addCustomCaption}
      dispositionSelected={dispositionSelected}
      setDispositionSelected={setDispositionSelected}
      setSilentDispositionSelected={setSilentDispositionSelected}
    />
  )

}

export default AgentSession;