import { Navigate, useLocation } from 'react-router-dom';
import { useOAuth } from 'modules/authentication/oauth.context';
import { Custom401 } from 'modules/401/custom-401.component';
import { config } from 'shared/config/cts.config';
import {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
  type ReactNode,
} from 'react';
import { setAccessToken } from 'shared/config/rest.config';
import { useLdIdentify } from 'shared/hooks/use-ld-identify.hook';
import { RootState } from 'state/store';
import { useSelector } from 'react-redux';
import { useFlags, type LDFlagSet } from 'launchdarkly-react-client-sdk';
import { useAllowScreenShare } from 'shared/hooks/use-allow-screen-share';

export const LANGUAGES_MAP = {
  english: 'English_US',
  spanish: 'Spanish_US',
};


type AuthenticationContextType = {
  user: any;
  getUserToken: () => string | undefined;
  loginValidated: boolean;
  validateLogin: () => boolean;
  logout: () => void;
};

const AuthenticationContext = createContext(
  {} as any as AuthenticationContextType
);

declare let pendo: any;

const AuthenticationProvider = ({ children }: { children: ReactNode }) => {
  const [loginValidated, setLoginValidated] = useState(true);
  const [showExpirationModal] = useState<boolean>(false);
  const [accessTokenWasSet, setAccessTokenWasSet] = useState(false);
  const { accessToken, logout, isLoading } = useOAuth();
  const { setLDUser } = useLdIdentify();
  const location = useLocation();
  const { useElectronQa } = useFlags<LDFlagSet>();

  useEffect(() => {
    if (accessToken) {
      setAccessTokenWasSet(true);
      setAccessToken(accessToken);
    }
  }, [accessToken]);

  const user = useSelector((state: RootState) => state.user.value);

  useEffect(() => {
    if (!setLDUser || !user) return;
    const ldUser = {
      key: user!.agentEmail,
      name: user!.given_name! + ' ' + user.sn,
      email: user!.agentEmail,
    };

    setLDUser(ldUser);

    pendo.initialize({
      visitor: {
        id: user!.username, // Required if user is logged in, default creates anonymous ID
        // email:           // Recommended if using Pendo Feedback, or NPS Email
        // full_name:       // Recommended if using Pendo Feedback
        // role:            // Optional
        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },
    });
  }, [user, setLDUser]);

  const getUserToken = useCallback(() => accessToken, [accessToken]);

  const validateLogin = () => {
    setLoginValidated(true);
    return true;
  };
  let destinationPath = '/download'
  if(!window?.electronAPI && config.REACT_APP_ENVIRONMENT === 'prod'){
    return <Navigate to={destinationPath} />;
  }
  if (location.pathname === '/' && !isLoading && user && (useElectronQa !== undefined)) {
    if(window?.electronAPI
    || useElectronQa
    || window.location.host === 'capzonedev.sorenson.com'
    ){
      destinationPath = '/agent-lobby'
    }
    return <Navigate to={destinationPath} />;
  }

  return (
    <AuthenticationContext.Provider
      value={{ user, getUserToken, loginValidated, validateLogin, logout }}
    >
      {children}
      {!user && !isLoading && <Custom401 onLogout={logout} />}
    </AuthenticationContext.Provider>
  );
};

export const useAuthenticationContext = () => {
  const ctx = useContext(AuthenticationContext);
  return ctx;
};

export { AuthenticationContext, AuthenticationProvider };
