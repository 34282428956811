import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import captionsReduser from './captions/captionsSlice';
import audioReducer from './audio/audioSlice';
import quickwordsReducer from './quickwords/quickwordsSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    captions: captionsReduser,
    audio: audioReducer,
    quickwords: quickwordsReducer,
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;