import { createSlice } from '@reduxjs/toolkit'

interface AudioState {
  muted: boolean,
}

const initialState: AudioState = {
  muted: false,
}

const audioSlice = createSlice({
  name: "audio",
  initialState,
  reducers: {
    mute: (state) => {
      state.muted = true;
    },
    unmute: (state) => {
      state.muted = false;
    },
    toggleMute: (state) => {
      state.muted = !state.muted;
    },
  }
})

export const { mute, unmute, toggleMute } = audioSlice.actions;

export default audioSlice.reducer;