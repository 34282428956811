import { AuthenticationContext } from 'modules/authentication/authentication.context';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import { useContext, useRef, useState } from 'react';
import { config } from 'shared/config/cts.config';
import { useCorrections } from 'shared/hooks/axon/corrections/use-corrections.hook';
import { incomingAudioSampleRate } from 'shared/hooks/axon/gateway.types';
import { handleAudio } from 'shared/hooks/axon/helpers/handle-audio.helper';
import {
  GatewayEvent,
  type ConnectingToCallEvent,
  type HangUpEvent,
} from 'shared/hooks/axon/ipcts-gw/gateway-events.enum';
import { useUpdateTranscript } from 'shared/hooks/axon/ipcts-gw/use-update-transcript.hook';
import { SoundBuffer } from 'shared/hooks/axon/ipcts-pfc-gw/SoundBuffer';
import { demoLog } from 'shared/utils/demo-log';
import { useSocketFactory } from 'shared/utils/socket-factory/use-socket-factory.hook';
import { RootState } from 'state/store';
import { useSelector, useDispatch } from 'react-redux';
import { clearCaptions } from 'state/captions/captionsSlice';
const READY_FOR_CALL_TIMER = 5000;

const useGateWay = () => {
  const notAcceptingCalls = useRef(false);
  const { getUserToken } = useContext(AuthenticationContext);
  const user = useSelector((state: RootState) => state.user.value);
  const audio = useSelector((state: RootState) => state.audio);
  const captions = useSelector((state: RootState) => state.captions.value)
  const dispatch = useDispatch();
  function getAudio(){
    return audio;
  }
  const {
    callId,
    setCallId,
    setShowLobby,
    setIsCallActive,
    detailedShards,
  } = useContext(LandingPageContext);
  const { updateTranscript } = useUpdateTranscript();
  const callAudioCtx = useRef<AudioContext>(new AudioContext());
  const soundBuffer = new SoundBuffer(
    callAudioCtx.current,
    incomingAudioSampleRate,
    2
  );

  const useGateWaySocket = useSocketFactory({
    wsUrl: `${config.REACT_APP_GW_SOCKET}?agent_id=${user?.employeeID}`,
  });

  const { send, openSocket, closeSocket, setEventsHandlers, isOpen } =
    useGateWaySocket();

  const { sendCorrection, sendDeletion, sendInsertion, sendSubstitution } =
    useCorrections(send);

  const closeConnections = async () => {
    dispatch(clearCaptions())
    closeSocket();
  };
  function timedReadyForCalls() {
    setTimeout(() => {
      if (notAcceptingCalls.current) {
        return;
      }
      demoLog('** GatewayEvent send timedReadyForCalls');
      send(
        JSON.stringify({
          event: GatewayEvent.readyForCalls, /// Time before new call comes in (this is temporary)
        })
      );
    }, READY_FOR_CALL_TIMER);
  }
  const sendNotAcceptingCalls = (status: string, reason: string) => {
    notAcceptingCalls.current = true;
    send(
      JSON.stringify({
        event: GatewayEvent.notAcceptingCalls,
        payload: {
          status,
          reason,
        },
      })
    );
  };
  const eventHandlers: Record<string, (value?: any) => any> = {
    [GatewayEvent.captions]: (msg: any) => {
      demoLog(
        '** GatewayEvent.captions payload: ',
        JSON.stringify(msg.payload)
      );
      updateTranscript(msg.payload);
    },
    [GatewayEvent.hello]: () => {
      demoLog('** GatewayEvent.hello');
      send(
        JSON.stringify({
          event: GatewayEvent.authenticate,
          payload: {
            token: getUserToken(),
          },
        })
      );
    },
    [GatewayEvent.ready]: (msg: any) => {
      demoLog('** GatewayEvent.ready ', msg);
      notAcceptingCalls.current = false;
      send(
        JSON.stringify({
          event: GatewayEvent.readyForCalls,
        })
      );
      setShowLobby(false);
      setIsCallActive(false);
    },
    [GatewayEvent.startAudio]: () => {
      demoLog('** GatewayEvent.startAudio');
    },
    [GatewayEvent.connectionError]: (msg: any) => {
      console.error('** GatewayEvent.error failed to connect ', msg);
    },
    [GatewayEvent.error]: (msg: any) => {
      demoLog('** GatewayEvent.error ', msg);
    },

    [GatewayEvent.captionsEnded]: () => {
      demoLog('** GatewayEvent.captionsEnded');
      closeSocket();
    },

    [GatewayEvent.connectionClosed]: () => {
      demoLog('** GatewayEvent.connectionClosed');
      dispatch(clearCaptions())
      setShowLobby(true);
      setIsCallActive(false);
    },

    [GatewayEvent.status]: (msg: any) => {
      demoLog('** GatewayEvent.status [socket msg] %o', msg);
    },
    [GatewayEvent.alternativesUnavailable]: () => {
      demoLog('** GatewayEvent.alternativesUnavailable');
      detailedShards.current = false;
    },
    [GatewayEvent.readyForCalls]: (msg: string) => {
      demoLog('** GatewayEvent.readyForCalls [socketmsg] %0');
    },
    [GatewayEvent.connectingToCall]: async (msg: ConnectingToCallEvent) => {
      demoLog('** GatewayEvent.connectingToCall [socketmsg] %0', msg);
      setCallId(msg.payload?.call_id);
      setShowLobby(false);
      setIsCallActive(true);
    },
    [GatewayEvent.hangup]: async (msg: HangUpEvent) => {
      demoLog('** GatewayEvent.hangup [socketmsg] %0', msg);
      setCallId('');
      setShowLobby(false);
      setIsCallActive(false);
      dispatch(clearCaptions());
      console.log('** GatewayEvent.hangup isOpen ', isOpen());
      timedReadyForCalls();
    },
    [GatewayEvent.audio]: handleAudioMessage,
  };
  function handleAudioMessage(msg: any){
    // demoLog('** GatewayEvent.audio ', getAudio().muted);
    if(!getAudio().muted){
      handleAudio(msg.payload, soundBuffer);
    }
  }

  const openConnection = async () => {
    if (isOpen()) {
      notAcceptingCalls.current = false;
      send(
        JSON.stringify({
          event: GatewayEvent.readyForCalls,
        })
      );
      setShowLobby(false);
      setIsCallActive(false);
    } else {
      setEventsHandlers(eventHandlers);
      openSocket();
    }
  };

  return {
    openConnection,
    closeConnections,
    sendCorrection,
    sendDeletion,
    sendInsertion,
    sendSubstitution,
    sendNotAcceptingCalls,
  };
};

export default useGateWay;
