import BottomControls from 'modules/ipcts-call-session/components/bottom-controls/bottom-controls.component';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import { NetworkStatus } from 'modules/ipcts-call-session/pfc-screen/components/network-status.component';
import PfcSettings from 'modules/ipcts-call-session/pfc-screen/components/pfc-settings.component';
import * as Styled from 'modules/ipcts-call-session/pfc-screen/preflight-check.styled';
import React from 'react';
import {
  ContentEditableEvent,
  Editor,
  EditorProvider,
} from 'shared/components/ui/editor';
import { CaptionShard, GateWaySockets } from 'shared/hooks/axon/gateway.types';
import { useNavigate } from 'react-router-dom';

interface PFCProps {
  setShowQueue: React.Dispatch<React.SetStateAction<boolean>>;
  setShowLobby: React.Dispatch<React.SetStateAction<boolean>>;
  text: CaptionShard[];
  handleStartListening: (socket: GateWaySockets) => void;
  handleStopListening: (socket: GateWaySockets) => void;
  cardCaptionCallRef: React.MutableRefObject<HTMLDivElement | null>;
  setIsSessionScreen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PreflightChekScreen = ({
  setShowQueue,
  setShowLobby,
  setIsSessionScreen,
  text,
  handleStartListening,
  handleStopListening,
  cardCaptionCallRef,
}: PFCProps) => {
  const textArea = React.useRef<HTMLElement>();
  const navigate = useNavigate();
  const {
    isActiveSession,
    captionAreaFontSize,
    captionAreaLineHeight,
    captionAreaFontFamily,
    reset,
  } = React.useContext(IPCTSSessionContext);

  const handdleStartTakingCalls = async () => {
    await handleStartListening(GateWaySockets.captionerSocket)
    reset();
    navigate('/agent-queue');
  };

  return (
    <Styled.PFCPageContainer>
      <Styled.LeftContainer>
        <Styled.CardCaptionCall
          ref={cardCaptionCallRef}
          className="vertical-default-scroll"
          isDisabled={false}
          fontSize={captionAreaFontSize}
          lineHeight={captionAreaLineHeight}
          fontFamily={captionAreaFontFamily}
        >
          <div className="editor-provider-wrapper">
            <EditorProvider>
              <Editor
                value={text}
              />
            </EditorProvider>
          </div>
        </Styled.CardCaptionCall>
        <BottomControls />
      </Styled.LeftContainer>
      <Styled.RightContainer>
        <PfcSettings />
        <Styled.ButtonContainer>
          <div>
            <NetworkStatus />
          </div>
          <Styled.StartButton id="nextButton" onClick={handdleStartTakingCalls}>
            Start taking calls
          </Styled.StartButton>
        </Styled.ButtonContainer>
      </Styled.RightContainer>
    </Styled.PFCPageContainer>
  );
};

export default PreflightChekScreen;