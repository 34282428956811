import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CaptionShard,
} from 'shared/hooks/axon/gateway.types';

interface Captions {
  value: CaptionShard[]
}

const initialState: Captions = {
  value: [],
}

type CaptionUserEditingPayload = {
  shardId: number;
  userEditing: boolean;
}

type CaptionShardWordTextPayload = {
  shardId: number,
  wordIndex: number,
  wordText: string,
}

const captionsSlice = createSlice({
  name: 'captions',
  initialState: initialState,
  reducers: {
    setCaption: (state, action: PayloadAction<CaptionShard>) => {
      state.value[action.payload.shardId] = action.payload
    },
    setAllCaptions: (state, action: PayloadAction<CaptionShard[]>) => {
      state.value = action.payload
    },
    clearCaptions: (state,) => {
      state.value = [];
    },
    setCaptionUserEditing: (state, action: PayloadAction<CaptionUserEditingPayload>) => {
      state.value[action.payload.shardId].userEditing = action.payload.userEditing;
    },
    setCaptionShardWordText: (state, action: PayloadAction<CaptionShardWordTextPayload>) => {
      state.value[action.payload.shardId].shardWords[action.payload.wordIndex].wordText = action.payload.wordText;
    },
  }
})

export const {
  setCaption,
  setAllCaptions,
  clearCaptions,
  setCaptionUserEditing,
  setCaptionShardWordText,
} = captionsSlice.actions;

export default captionsSlice.reducer;