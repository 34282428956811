import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'state/store';

export type LDUser = {
  key?: string;
  name: string;
  email: string;
};

export const useLdIdentify = () => {
  const ldClient = useLDClient();

  const setLDUser = async (ldUser: LDUser) => {
    if (!ldClient) return;
    try {
      let result = await ldClient?.identify(ldUser);
      console.log('setLDUser result ', result)
      return result;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return { setLDUser };
};
