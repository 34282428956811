import CCLogo from 'assets/icons/captioncall-logo.svg';
import { AudioOptions } from 'modules/ipcts-call-session/components/controls/audio-options.component';
import { TextOptions } from 'modules/ipcts-call-session/components/controls/text-options.component';
import * as Styled from './pfc-settings.styled';

const PfcSettings = () => (
  <Styled.CallControlsContainer>
    <Styled.PFCTitle>
      <Styled.CCIcon src={CCLogo} /> CaptionCall
    </Styled.PFCTitle>
    <Styled.CallControlsBody id="call-controls-body">
      <AudioOptions showLabels={false} />
      <div>
        <TextOptions showLabels={false} />
      </div>
    </Styled.CallControlsBody>
  </Styled.CallControlsContainer>
);

export default PfcSettings;
