import { config } from 'shared/config/cts.config';
import { QuickWord, QuickWordObject, setFunctionKeys, setQuickwords } from 'state/quickwords/quickwordsSlice';
import { useDispatch } from 'react-redux';

export const useQuickWords = () => {
  const dispatch = useDispatch()

  const FUNCTION_KEY_REGEX = /F\d+/;
  const quickWordConverter = (quickwords: QuickWord[]) =>
    quickwords.reduce((acc: QuickWordObject, quickword: QuickWord) => {
      const { abbreviation, description } = quickword;
      acc[abbreviation] = description;
      return acc;
    }, {});

  async function loadQuickwords(accessToken: string){
    try {
      const headers: HeadersInit = new Headers();
      headers.set('Authorization', `Bearer ${accessToken}`);
      const data = await fetch(
        `${config.REACT_APP_CAPTIONER_SERVICES_API}/quickwords`,
        {
          method: 'GET',
          headers,
          mode: 'cors',
        }
      );
      const parsedData = await data.json();

      let quickwords = quickWordConverter(
        parsedData?.data?.filter(
          (quickword: QuickWord) =>
            !quickword.abbreviation.match(FUNCTION_KEY_REGEX)
        )
      )
      let functionKeys = quickWordConverter(
        parsedData?.data
          ?.filter((quickword: QuickWord) =>
            quickword?.abbreviation.match(FUNCTION_KEY_REGEX)
          )
          .map((quickword: QuickWord) => ({
            ...quickword,
            abbreviation: quickword.abbreviation.split('_')[0],
          }))
      );
      dispatch(setQuickwords(quickwords));
      dispatch(setFunctionKeys(functionKeys));

    } catch (error) {
      console.log(error);
    }
  };
  return {
    loadQuickwords,
  };  
}